import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Translation } from 'react-i18next'
// import { Link as RebassLink } from 'rebass/styled-components'
import { Box } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'
import { withI18next } from 'gatsby-plugin-i18n'

const BlogPostTemplate = ({ data, pageContext }) => {
  const { blogPost } = data
  const { language } = pageContext
  const { title, content } = blogPost
  const contentHTML = draftjsContentToHTML(content)

  return (
    <Translation>
      {(t) => (
        <Layout language={language}>
          <main>
            <LightSection>
              <Container maxWidth="45rem">
                <SectionTitle as="h1">{title}</SectionTitle>

                <Box
                  dangerouslySetInnerHTML={{ __html: contentHTML }}
                  sx={{ fontSize: 3 }}
                />
              </Container>
            </LightSection>
          </main>
        </Layout>
      )}
    </Translation>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(BlogPostTemplate)

export const pageQuery = graphql`
  query BlogPostTemplate($id: String!, $language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
    blogPost: blogPosts(id: { eq: $id }) {
      id
      title
      slug
      content
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
